import {useState} from "react";
import Loading from "./Loading";
import {Alert, Button, Container, FormField, Header, Input, SpaceBetween, Toggle} from "@cloudscape-design/components";
import {cloudcallIdValidator} from "../Helpers/validator";
import {ApiHelper} from "../Helpers/apiHelper";
import CustomerIdSelector from "./CustomerIdSelector";
import ConnectorSelector from "./ConnectorSelector";

interface TriggerContactSyncProps {
    initialConnectorId?: string | null,
    initialCustomerId?: string | null,
}


const TriggerContactSync = ({initialConnectorId, initialCustomerId}: TriggerContactSyncProps) => {
    const [error, setError] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [connectorId, setConnectorId] = useState(initialConnectorId || "");
    const [customerId, setCustomerId] = useState(initialCustomerId || "");
    const [fullSync, setFullSync] = useState(false);
    const [apiHelper] = useState(ApiHelper())
    const [connectorName, setConnectorName] = useState("");


    const readyForSubmit = () => {
        if (Math.min(customerId.length,connectorId.length) === 0) return false;
        if (connectorName === 'TrackerRms') return false;  
        return (cloudcallIdValidator(customerId) && cloudcallIdValidator(connectorId));
    }
    const clearError = () => {
        setError(null)
    }
    const submitForm = () => {
        setIsLoading(true);
        clearError();
        apiHelper.makeSignedRequest(`api/syncCore/sync`, "POST",{
            clientConnectorId: connectorId,
            fullSync: fullSync,
            customerId: customerId
        })
            .then((res)=> {
                setIsLoading(false);
                setError(<Alert
                    statusIconAriaLabel="Success"
                    dismissible
                    onDismiss={clearError}
                    //@ts-ignore
                    type="success"
                    header="Done"
                >
                    Contact sync was requested
                </Alert>)
            })
            .catch((e)=>{
                console.error(e)
                setIsLoading(false);
                setError(<Alert
                    statusIconAriaLabel="Error"
                    dismissible
                    onDismiss={clearError}
                    //@ts-ignore
                    type="error"
                    header="Could not trigger contact sync"
                >
                    An error occurred trying to trigger this sync, please check the entered credentials or try again later
                </Alert>)
            })
    }
    if (isLoading) {
        return  <Loading />
    }
    return (
        <Container header={
            <Header variant="h3" description={"Attempt an asynchronous contact sync for the selected customer"}>
                Trigger Contact Sync
            </Header>
        }>
            <SpaceBetween size="m">
                {error}
                {connectorName === 'TrackerRms' && (
                    <Alert
                        statusIconAriaLabel="Warning"
                        type="warning"
                        header="Warning"
                    >
                        Contact sync is not available for {connectorName} connectors
                    </Alert>
                )}

                <FormField
                    description="Customer ID"
                >
                    <CustomerIdSelector
                        initialValue={customerId}
                        onChange={setCustomerId}
                        invalid={!cloudcallIdValidator(customerId)}
                    />
                </FormField>
                <FormField
                    description="Client Connector Id"
                >
                    <ConnectorSelector customerId={customerId} onChange={(id: string, name: string) => {
                            setConnectorId(id);
                            setConnectorName(name);
                        }} 
                    />
                </FormField>
                <FormField
                    description="Full Sync?"
                >
                    <Toggle
                        onChange={({ detail }) =>
                            setFullSync(detail.checked)
                        }
                        checked={fullSync}
                    >
                        {fullSync ? 'Trigger a full sync, this is an expensive and slow process' : 'Trigger an incremental sync'}
                    </Toggle>
                </FormField>
                <Button variant={"primary"} onClick={submitForm} disabled={!readyForSubmit()}>
                    Submit
                </Button>
            </SpaceBetween>
        </Container>
    )

}

export default TriggerContactSync
