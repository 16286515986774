import GetSalesforceAuthUrl from "./GetSalesforceAuthUrl";
import SetBullhornAuth from "./SetBullhornAuth";
import GetVincereAuthUrl from "./GetVincereAuthUrl";
import GetTrackerAuthUrl from "./GetTrackerAuthUrl";


const AvailableConnectors = {
    Salesforce: GetSalesforceAuthUrl,
    Bullhorn: SetBullhornAuth,
    Vincere: GetVincereAuthUrl,
    TrackerRms: GetTrackerAuthUrl,
}

export default AvailableConnectors;
