import React, {useState} from "react";
import {
    Alert,
    Button, CopyToClipboard, FormField,
    SpaceBetween
} from "@cloudscape-design/components";
import {ApiHelper} from "../../Helpers/apiHelper";
import {cloudcallIdValidator} from "../../Helpers/validator";
import CustomerIdSelector from "../CustomerIdSelector";
import AuthConnectorProps from "./ConnectorTypes";

const connectorName = "TrackerRms";

const GetTrackerAuthUrl = ({ setNotifications, clearNotifications, setIsLoading, connectorId }: AuthConnectorProps ) => {
    const [apiHelper] = useState(ApiHelper())
    const [customerId, setCustomerId] = useState("");
    const getButtonIsDisabled = () => {
        return (customerId.length === 0 || !cloudcallIdValidator(customerId));
    }

    const getUrl = async () => {
        setIsLoading(true);
        clearNotifications();
        const existingConnectors = await apiHelper.makeSignedRequest(`api/clientConnectors?customerId=${customerId}`)
            .then((res) => {
                return res?.data.filter((connector: any) => {
                    return connector?.clientConfiguration?.integrationName === connectorName
                });
            })
        const isUpdate = existingConnectors.length > 0;
        let targetUrl = `api/connectors/v1/oauth/login?connectorName=${connectorName}&customerId=${customerId}&connectorId=${connectorId}`;
        let customerMessage = `Please send the following url to the customer to create a new ${connectorName} integration:`;
        if (isUpdate) {
            const targetIntegrationId = existingConnectors[0].id;
            targetUrl = `${targetUrl}&existingConnectionId=${targetIntegrationId}`
            customerMessage = `Please send the following url to the customer to update their existing ${connectorName} integration:`;
        }
        apiHelper.makeSignedRequest(targetUrl).then((res) => {
            let error = (
                <Alert
                    statusIconAriaLabel="Info"
                    dismissible
                    //@ts-ignore
                    onDismiss={clearNotifications}
                    //@ts-ignore
                    type="error"
                    header="Error"
                    key={"errorFetching"}
                >
                    An error occurred fetching the url, please check the values entered or refresh and try again
                </Alert>
            )
            if (res && res.data.redirectUrl) {
                error = (<Alert
                    statusIconAriaLabel="Info"
                    dismissible
                    //@ts-ignore
                    onDismiss={clearNotifications}
                    //@ts-ignore
                    type="success"
                    header="Request Successful"
                    key={"success"}
                >
                    <SpaceBetween size={"xs"}>
                        <div key={"title"}>
                            {customerMessage}
                        </div>
                        <CopyToClipboard
                            copyButtonText="Copy"
                            copyErrorText="URL failed to copy"
                            copySuccessText="URL copied"
                            variant={"inline"}
                            //@ts-ignore
                            textToCopy={res.data.redirectUrl}
                            key={"copy"}
                        />
                    </SpaceBetween>
                </Alert>)
            }
            setNotifications(error);
        }).catch((e) => {
            let message = "An error occurred fetching the url, please check the values entered or refresh and try again";
            let header = "Invalid Request"
            setNotifications(<Alert
                statusIconAriaLabel="Error"
                dismissible
                //@ts-ignore
                onDismiss={clearNotifications}
                //@ts-ignore
                type="error"
                header={header}
                key={"errorFetching"}
            >
                {message}
            </Alert>)
        })
            .finally(() => {
                setIsLoading(false)
            })
    };

    return (
        <>
            <SpaceBetween size={"m"} >
                <FormField
                    description="Customer ID"
                >
                    <CustomerIdSelector
                        initialValue={customerId}
                        onChange={setCustomerId}
                        invalid={!cloudcallIdValidator(customerId)}
                    />
                </FormField>
                <Button
                    variant={"primary"}
                    disabled={getButtonIsDisabled()}
                    onClick={()=>getUrl()}>
                    Get Auth URL
                </Button>
            </SpaceBetween>
        </>
    )
}

export default GetTrackerAuthUrl;
